<template>
  <v-container>
    <v-row class="my-1">
      <v-flex md12>
        <h5>
          Latest products from Distributors
        </h5>
      </v-flex>
    </v-row>

    <!--Product Session-->
    <v-row class="productContainer mb-10">
      <template>
        <v-col
          v-for="product in productLists.slice(0, 8)"
          :key="product.id"
          cols="6"
          md="3"
        >
          
            <v-sheet
              height="auto"
              style="background-color:#C4C4C4;border-radius:10px;"
            >
              <v-img
                  height="150"
                  :src="product.productImagePath"
                  cover
                  style="border-radius:15px 15px 0px 0px;"
                  @click="viewSingleProduct(product)"
                >
                  
                  <h6 align="center" class="priceStyle2" v-if="product.needsPrescription == false">
                    Sale!
                  </h6>

                  <h6 align="center" class="priceStyle3" v-if="product.needsPrescription">
                    Prescription
                  </h6>
                </v-img>

                <p style="background-color:#ffffff; padding:10px;">
                  <span style="font-size:12px;">{{
                    product.ProductCategory.name
                  }}</span>
                  <br />
                  <span style="font-size:12px;"
                    ><b>{{ product.name }}</b></span
                  >
                  <br />
                  <span
                    ><b
                      >{{ systemSetting.systemCurrency }} {{ product.price }}</b
                    >
                  </span>
                  <br/>
                  <v-btn class="buttonStyle mr-5" small  @click="viewSingleProduct(product)">
                            Buy Now
                          </v-btn>
                </p>
            </v-sheet>
        </v-col>
      </template>
    </v-row>
    <v-dialog v-model="dialogSingleProduct" width="800">
                <v-card>
                  <v-card-text class="mt-4">
                    <v-row wrap>
                      <v-col cols="6">
                        <v-img
                          height="250"
                          :src="singleProduct.productImagePath"
                          contain
                        />
                      </v-col>
                      <v-col cols="6">
                        <div>
                          <h4>{{ singleProduct.name }}</h4>
                          <p>
                            <b>
                              {{ systemSetting.systemCurrency }}
                              {{ singleProduct.price }}
                            </b>
                          </p>
                          <p>
                            {{ singleProduct.description }}
                          </p>
                          <p><b>Availability:</b> In Stock</p>
                          <p>
                            <b>Quantity:</b>
                            <span class="ml-5">
                              <v-icon
                                dark
                                color="#72bd61"
                                class="mr-3"
                                @click="decreamentCounter"
                                >mdi-minus-circle</v-icon
                              >
                              {{ cartData.quantity }}
                              <v-icon
                                dark
                                color="#72bd61"
                                class="ml-3"
                                @click="increamentCounter"
                                >mdi-plus-circle</v-icon
                              >
                            </span>
                          </p>
                          <p class="mt-5" style="color:#F01010">
                            Quantity supplies are measured in Cartoons
                          </p>
                        </div>

                        <v-divider></v-divider>

                        <div>
                          <v-btn class="buttonStyle mr-5 ml-5" @click="addCart">
                            Add to Cart
                          </v-btn>

                          <v-btn class="buttonStyleGray" :to="'/cart/overview'">
                            Checkout
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogAddToCart" width="700" persistent>
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="11" align="center">
                        <v-icon size="60" color="#72BD61"
                          >mdi-check-circle</v-icon
                        >
                        <br />
                        <span style="font-size:16px;">
                          {{ singleProduct.name }} has been added to your
                          shopping cart.
                        </span>
                        <br/>
                          <v-btn class="buttonStyle mr-5 ml-5 mt-5" @click="reloadPage">
                            Continue
                          </v-btn>
                      </v-col>

                      <v-col cols="1" align="right">
                        <v-btn
                          icon
                          color="black"
                          @click="reloadPage"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "DistributorProducts",

  data: () => ({
    productLists: [],
    systemSetting: {
      systemCurrency: "₦",
    },
    dialogSingleProduct: false,
    dialogAddToCart: false,
    singleProduct: {},
    cartData: {
      productId: "",
      quantity: 1,
    },
    roleBody: {
      name: "distributor",
    },
  }),

  created() {
    this.getProducts();
  },

  methods: {
    increamentCounter() {
      this.cartData.quantity = this.cartData.quantity + 1;
    },
    decreamentCounter() {
      if (this.cartData.quantity <= 0) {
        this.cartData.quantity = 1;
      }
      this.cartData.quantity = this.cartData.quantity - 1;
    },
    viewSingleProduct(product) {
      this.dialogSingleProduct = true;
      console.log(product);
      this.singleProduct = product;
      this.cartData.productId = product.productId;
    },
    getProducts() {
      this.$http
        .post(`${process.env.VUE_APP_URL}product/role`, this.roleBody)
        .then((response) => {
          if (response.data.success == true) {
            this.productLists = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    addCart() {
      this.$http
        .post(
          `${process.env.VUE_APP_URL}cart/add/` +
            localStorage.getItem("userId"),
          this.cartData
        )
        .then((response) => {
          this.dialogAddToCart = true;
          const cartListItems = response.data.data.CartItems;
          let lastCartItem = cartListItems[cartListItems.length - 1];
          let recentCart = JSON.parse(localStorage.getItem("cartItems"));
          recentCart.push(lastCartItem);
        })
        .catch((error) => {
          console.log(error.reponse);
        });
    },
    reloadPage(){
      location.reload();
    }
  },
};
</script>

<style scoped>
.priceStyle {
  width: 80px;
  height: auto;
  background-color: black;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}


.priceStyle2 {
  width: 80px;
  height: auto;
  background-color: #bd2121;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}

.priceStyle3 {
  width: 100px;
  font-size: 12px;
  height: auto;
  background-color: #198754;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}

.buttonStyle {
  margin-top: 10px;
  width: auto;
  height: auto;
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 10px;
}

.productContainer {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 40px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
}
</style>
